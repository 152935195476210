var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sideNav",
      staticClass: "side-nav-wrapper",
      attrs: { tabindex: "0" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.toggleNav.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: _vm.showAppNavView ? "bottom-nav" : "side-nav" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.navShown,
                  expression: "navShown",
                },
              ],
              staticClass: "side-nav",
              class: _vm.showAppNavView ? "bottom-offset" : "",
              style: {
                width: "" + _vm.width,
                color: _vm.$themeTokens.text,
                backgroundColor: _vm.$themeTokens.surface,
              },
            },
            [
              _c(
                "FocusTrap",
                {
                  on: {
                    shouldFocusFirstEl: function ($event) {
                      return _vm.$emit("shouldFocusFirstEl")
                    },
                    shouldFocusLastEl: _vm.focusLastEl,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.showAppNavView
                        ? "bottom-nav-scrollable-area"
                        : "side-nav-scrollable-area",
                      style: _vm.showAppNavView
                        ? { width: "" + _vm.width }
                        : {
                            top: _vm.topBarHeight + "px",
                            width: "" + _vm.width,
                          },
                    },
                    [
                      _vm.themeConfig.sideNav.topLogo
                        ? _c("img", {
                            staticClass: "logo",
                            style: _vm.themeConfig.sideNav.topLogo.style,
                            attrs: {
                              src: _vm.themeConfig.sideNav.topLogo.src,
                              alt: _vm.themeConfig.sideNav.topLogo.alt,
                            },
                          })
                        : _vm._e(),
                      _vm.userIsLearner || _vm.showAppNavView
                        ? _c(
                            "div",
                            { staticClass: "user-information" },
                            [
                              _vm.showAppNavView
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "10px",
                                        "margin-left": "-15px",
                                      },
                                    },
                                    [
                                      _c("KIconButton", {
                                        ref: "closeButton",
                                        staticClass: "side-nav-header-icon",
                                        attrs: { icon: "close" },
                                        on: { click: _vm.toggleNav },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("TotalPoints", { staticClass: "points" }),
                              _c("b", [_vm._v(_vm._s(_vm.fullName))]),
                              _c(
                                "p",
                                {
                                  style: {
                                    color: _vm.$themeTokens.annotation,
                                    fontSize: "12px",
                                    marginTop: "8px",
                                    marginBottom: 0,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.username) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  style: {
                                    color: _vm.$themeTokens.annotation,
                                    fontSize: "12px",
                                    marginTop: 0,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.loggedInUserKind) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm.isLearnerOnlyImport
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-test": "syncStatusInDropdown",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "sync-status" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$tr("deviceStatus")) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _c("SyncStatusDisplay", {
                                        attrs: {
                                          syncStatus: _vm.userSyncStatus,
                                          lastSynced: _vm.userLastSynced,
                                          displaySize: "small",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.userIsLearner
                        ? _c("SideNavDivider", {
                            style: { listStyleType: "none" },
                          })
                        : _vm._e(),
                      _c("CoreMenu", {
                        ref: "coreMenu",
                        style: {
                          backgroundColor: _vm.$themeTokens.surface,
                          width: _vm.width,
                        },
                        attrs: {
                          role: "navigation",
                          "aria-label": _vm.$tr("navigationLabel"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "options",
                            fn: function () {
                              return [
                                _vm._l(_vm.topComponents, function (component) {
                                  return _c("CoreMenuOption", {
                                    key: component.name,
                                    attrs: {
                                      label: component.label,
                                      subRoutes: component.routes,
                                      link: component.url,
                                      icon: component.icon,
                                      "data-test": "side-nav-component",
                                    },
                                    on: { toggleMenu: _vm.toggleNav },
                                  })
                                }),
                                _c("SideNavDivider"),
                                _vm._l(
                                  _vm.accountComponents,
                                  function (component) {
                                    return _c("CoreMenuOption", {
                                      key: component.name,
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        label: component.label,
                                        subRoutes: component.routes,
                                        link: component.url,
                                        icon: component.icon,
                                        "data-test": "side-nav-component",
                                      },
                                      on: { toggleMenu: _vm.toggleNav },
                                    })
                                  }
                                ),
                                _vm.isUserLoggedIn
                                  ? _c("LogoutSideNavEntry")
                                  : _vm._e(),
                                _c("CoreMenuOption", {
                                  staticClass: "pointer",
                                  attrs: {
                                    label: _vm.coreString(
                                      "changeLanguageOption"
                                    ),
                                    icon: "language",
                                  },
                                  on: {
                                    select: _vm.handleShowLanguageModal,
                                    toggleMenu: _vm.toggleNav,
                                  },
                                }),
                                _c("SideNavDivider"),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _vm.showSoudNotice
                        ? _c(
                            "div",
                            { staticStyle: { padding: "16px" } },
                            [_c("LearnOnlyDeviceNotice")],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "side-nav-scrollable-area-footer",
                          style: { color: _vm.$themeTokens.annotation },
                        },
                        [
                          _vm.themeConfig.sideNav.brandedFooter
                            ? [
                                _vm.themeConfig.sideNav.brandedFooter.logo
                                  ? _c("img", {
                                      staticClass:
                                        "side-nav-scrollable-area-footer-logo",
                                      style:
                                        _vm.themeConfig.sideNav.brandedFooter
                                          .logo.style,
                                      attrs: {
                                        src: _vm.themeConfig.sideNav
                                          .brandedFooter.logo.src,
                                        alt: _vm.themeConfig.sideNav
                                          .brandedFooter.logo.alt,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.themeConfig.sideNav.brandedFooter
                                  .paragraphArray &&
                                _vm.themeConfig.sideNav.brandedFooter
                                  .paragraphArray.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "side-nav-scrollable-area-footer-info",
                                      },
                                      _vm._l(
                                        _vm.themeConfig.sideNav.brandedFooter
                                          .paragraphArray,
                                        function (line, index) {
                                          return _c("p", { key: index }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(line) +
                                                "\n                "
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm.themeConfig.sideNav.showKolibriFooterLogo
                            ? _c("CoreLogo", {
                                staticClass:
                                  "side-nav-scrollable-area-footer-logo",
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "side-nav-scrollable-area-footer-info",
                            },
                            [
                              _c("p", [_vm._v(_vm._s(_vm.footerMsg))]),
                              _c("p", [
                                _vm._v(
                                  "© " +
                                    _vm._s(_vm.copyrightYear) +
                                    " Learning Equality"
                                ),
                              ]),
                              _c(
                                "p",
                                [
                                  _c("KButton", {
                                    ref: "privacyLink",
                                    staticClass: "privacy-link",
                                    attrs: {
                                      text: _vm.coreString(
                                        "usageAndPrivacyLabel"
                                      ),
                                      appearance: "basic-link",
                                    },
                                    on: { click: _vm.handleClickPrivacyLink },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  !_vm.isAppContext || !_vm.isTouchDevice || _vm.windowIsLarge
                    ? _c(
                        "div",
                        {
                          staticClass: "side-nav-header",
                          style: {
                            height: _vm.topBarHeight + "px",
                            width: "" + _vm.width,
                            paddingTop: _vm.windowIsSmall ? "4px" : "8px",
                            backgroundColor: _vm.$themeTokens.appBar,
                          },
                        },
                        [
                          _c("KIconButton", {
                            ref: "closeButton",
                            staticClass: "side-nav-header-icon",
                            attrs: {
                              tabindex: "0",
                              icon: "close",
                              color: _vm.$themeTokens.textInverted,
                              ariaLabel: _vm.$tr("closeNav"),
                              size: "large",
                            },
                            on: { click: _vm.toggleNav },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "side-nav-header-name",
                              style: { color: _vm.$themeTokens.textInverted },
                            },
                            [_vm._v(_vm._s(_vm.sideNavTitleText))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.showAppNavView
        ? _c("BottomNavigationBar", {
            attrs: {
              bottomMenuOptions: _vm.bottomMenuOptions,
              navShown: _vm.navShown,
            },
            on: { toggleNav: _vm.toggleNav },
          })
        : _vm._e(),
      _c("Backdrop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.navShown && !_vm.isAppContext,
            expression: "navShown && !isAppContext",
          },
        ],
        staticClass: "side-nav-backdrop",
        attrs: { transitions: true },
        on: { click: _vm.toggleNav },
      }),
      _vm.privacyModalVisible
        ? _c("PrivacyInfoModal", {
            on: {
              cancel: function ($event) {
                _vm.privacyModalVisible = false
              },
              submit: function ($event) {
                _vm.privacyModalVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.languageModalShown
        ? _c("LanguageSwitcherModal", {
            ref: "languageSwitcherModal",
            style: { color: _vm.$themeTokens.text },
            on: {
              cancel: function ($event) {
                _vm.languageModalShown = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }