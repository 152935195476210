<template>

  <div class="container">
    <div class="rel">
      <div class="flapping-kolibri">
        <svg
          class="svg"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-labelledby="kolibri-loader"
          viewBox="0 0 1000 809"
          width="125"
          height="125"
        >
          <title id="kolibri-loader">{{ $tr('kolibriLoading') }}</title>
          <use xlink:href="#body" />
          <use xlink:href="#right-wing-inner" class="wing-inner" />
          <use xlink:href="#right-wing-middle" class="wing-middle" />
          <use xlink:href="#right-wing-outer" class="wing-outer" />
          <use xlink:href="#left-wing-inner" class="wing-inner" />
          <use xlink:href="#left-wing-middle" class="wing-middle" />
          <use xlink:href="#left-wing-outer" class="wing-outer" />
          <defs>
            <g id="body">
              <path fill="#9ab2a4" d="M487.722 127.162l34.806 30.323 25.198-37.05" />
              <path fill="#c8e1d1" d="M569.934 155.99l-22.208-35.554-25.198 37.05" />
              <path fill="#87afa6" d="M487.722 127.162l-44.416 50.93 79.222-20.607" />
              <path fill="#b8c7c1" d="M432.842 236.28l89.686-78.795-79.222 20.606" />
              <path fill="#acdcd2" d="M574.845 241.298l-4.91-85.308-47.407 1.495" />
              <path fill="#bed7cf" d="M528.4 253.897l-5.872-96.412-89.686 78.795" />
              <path fill="#a0d0c5" d="M574.845 241.298l-46.444 12.6-5.872-96.413" />
              <path fill="#7d8986" d="M423.66 262.972l104.74-9.075-95.558-17.617" />
              <path fill="#91aaae" d="M528.4 253.897L393.018 311.98l30.643-49.008" />
              <path fill="#7baea9" d="M509.61 333.333L393.016 311.98 528.4 253.897" />
              <path fill="#c9e8e1" d="M574.845 241.298l-65.236 92.035 18.79-79.436" />
              <path fill="#dbefe5" d="M609.652 319.24L509.61 333.333l65.235-92.035" />
              <path fill="#9cc8d2" d="M506.3 497.65l3.31-164.317-116.593-21.353" />
              <path fill="#a1afbc" d="M397.715 476.19L506.3 497.65 393.016 311.98" />
              <path fill="#c5e0df" d="M609.652 319.24L506.3 497.65l3.31-164.317" />
              <path fill="#dae7e7" d="M609.332 477.685L506.3 497.65l103.35-178.41" />
              <path fill="#b8bac8" d="M505.872 549.86l103.46-72.175L506.3 497.65" />
              <path fill="#8f9caf" d="M397.715 476.19l108.157 73.67.427-52.21" />
              <path fill="#8c7f96" d="M462.844 579.116l43.028-29.255-108.157-73.67" />
              <path fill="#776d82" d="M547.406 579.116l-41.534-29.255-43.028 29.256" />
              <path fill="#97a2b1" d="M547.406 579.116l-41.534-29.255 103.46-72.175" />
              <path fill="#9f96ae" d="M509.93 608.798l37.476-29.682h-84.562" />
              <path fill="#9792b5" d="M396.327 748.88L509.93 608.797l-47.086-29.682" />
              <path fill="#aba0cb" d="M480.675 809.31l-84.455-60.004 113.71-140.508" />
              <path fill="#c3b1d6" d="M535.127 808.883l-54.452.427 29.255-200.512" />
              <path fill="#dcbfdc" d="M618.514 749.626l-83.387 59.257-25.197-200.085" />
              <path fill="#b8cde6" d="M547.406 579.116l71.108 170.51L509.93 608.798" />
              <path fill="#a5b499" d="M770.446 0L612.962 75.913l-.214 28.187" />
              <path fill="#7f846d" d="M612.962 75.913l-.214 28.187-42.814 51.89" />
              <path fill="#a2aa97" d="M547.726 120.436l65.236-44.523-43.028 80.077" />
            </g>
            <g id="right-wing-inner">
              <path fill="#7ba9ae" d="M651.185 277.6l41.32-39.825-12.706 56.267" />
              <path fill="#768e98" d="M733.825 273.756l-41.32-35.98L679.8 294.04" />
              <path fill="#dae6f3" d="M701.046 319.24L679.8 294.042l54.025-20.286" />
              <path fill="#80a6b0" d="M757.314 315.93l-23.49-42.174-32.778 45.484" />
              <path fill="#7b8b9b" d="M710.976 347.854l-9.93-28.614 56.268-3.31" />
              <path fill="#d9dff1" d="M765.855 364.083l-8.54-48.153-46.34 31.924" />
              <path fill="#83a3b1" d="M711.19 378.497l-.214-30.643 54.88 16.23" />
              <path fill="#828a9f" d="M757.634 410.848l8.22-46.765-54.665 14.414" />
              <path fill="#d9d8ed" d="M702.648 404.442l8.54-25.945 46.446 32.35" />
              <path fill="#899db2" d="M735.32 451.634l22.314-40.786-54.986-6.406" />
              <path fill="#838aa0" d="M684.07 431.56l18.578-27.118 32.67 47.192" />
              <path fill="#d8d3ea" d="M692.825 488.47l42.494-36.836-51.25-20.073" />
              <path fill="#879fb2" d="M650.972 448.75l33.098-17.19 8.755 56.91" />
            </g>
            <g id="right-wing-middle">
              <path fill="#8cb7c4" d="M800.77 178.625l-25.946-18.47-13.773 52.422" />
              <path fill="#a7dde9" d="M781.55 230.3l-20.5-17.723 39.72-33.952" />
              <path fill="#6a909a" d="M832.16 207.88l-31.39-29.255-19.22 51.676" />
              <path fill="#88bdc4" d="M801.73 252.51l-20.18-22.21 50.61-22.42" />
              <path fill="#a7cce4" d="M859.065 245.676L832.16 207.88l-30.43 44.63" />
              <path fill="#718e9d" d="M821.268 285.18l-19.538-32.67 57.335-6.834" />
              <path fill="#a8d5e7" d="M878.817 290.626l-19.752-44.95-37.797 39.504" />
              <path fill="#90b1c5" d="M833.44 321.482l-12.172-36.302 57.55 5.446" />
              <path fill="#778a9f" d="M887.786 337.818l-54.346-16.336 45.377-30.856" />
              <path fill="#a7cce4" d="M837.924 365.15l-4.484-43.668 54.346 16.336" />
              <path fill="#94a7c6" d="M886.504 393.23l-48.58-28.08 49.862-27.332" />
              <path fill="#7e89a7" d="M831.518 406.47l6.406-41.32 48.58 28.08" />
              <path fill="#aac1e3" d="M874.867 439.782l-43.35-33.312 54.987-13.24" />
              <path fill="#95a5c5" d="M818.386 438.82l13.132-32.35 43.35 33.312" />
              <path fill="#8586a8" d="M854.153 481.636l20.714-41.854-56.48-.96" />
              <path fill="#abc0e3" d="M802.477 466.26l15.91-27.44 35.766 42.816" />
              <path fill="#9a9fc7" d="M828.208 515.695l25.945-34.06-51.676-15.374" />
              <path fill="#8585a9" d="M782.938 488.47l19.54-22.21 25.73 49.435" />
              <path fill="#9a9fc7" d="M800.342 541.106l27.866-25.41-45.27-27.227" />
              <path fill="#99a2c7" d="M757.527 508.755l25.41-20.286 17.405 52.636" />
              <path fill="#aeb8de" d="M770.446 561.5l29.896-20.394-42.815-32.35" />
              <path fill="#abbfe3" d="M729.767 524.237l27.76-15.482 12.92 52.744" />
              <path fill="#6a909a" d="M761.05 212.577l-25.837-14.84 39.61-37.583" />
            </g>
            <g id="right-wing-outer">
              <path fill="#6c949b" d="M835.576 145.74l-27.76-17.19 41.853-40.252" />
              <path fill="#57787e" d="M890.028 119.688l-40.36-31.39-14.092 57.442" />
              <path fill="#95c7d0" d="M866.432 172.325l-30.856-26.585 54.452-26.052" />
              <path fill="#71939d" d="M922.806 154.068l-32.778-34.38-23.596 52.637" />
              <path fill="#5e7681" d="M893.124 203.716l-26.692-31.39 56.374-18.258" />
              <path fill="#99c1d0" d="M951.207 191.757l-28.4-37.69-29.683 49.65" />
              <path fill="#7490a1" d="M911.702 232.864l-18.578-29.148 58.083-11.96" />
              <path fill="#657687" d="M971.706 230.515l-20.5-38.758-39.504 41.107" />
              <path fill="#9cbacf" d="M928.57 270.34l-16.868-37.476 60.004-2.35" />
              <path fill="#7c8ca6" d="M988.47 277.173l-16.764-46.658-43.135 39.825" />
              <path fill="#6d758f" d="M941.597 318.28l-13.026-47.94 59.9 6.833" />
              <path fill="#a1b2d1" d="M997.865 327.78l-9.396-50.607-46.873 41.106" />
              <path fill="#7b7397" d="M1000 379.99l-2.135-52.21-51.89 27.227" />
              <path fill="#8389aa" d="M941.597 318.28l4.378 36.727 51.89-27.226" />
              <path fill="#a4accf" d="M943.626 400.064l2.35-45.057L1000 379.99" />
              <path fill="#8c86ae" d="M994.982 426.116L1000 379.99l-56.374 20.074" />
              <path fill="#7f739b" d="M935.51 440.21l8.116-40.146 51.356 26.052" />
              <path fill="#a6a2cd" d="M984.84 466.154l10.142-40.038-59.47 14.093" />
              <path fill="#9085ae" d="M922.7 477.045l12.81-36.836 49.33 25.944" />
              <path fill="#8572a1" d="M966.047 511.958l18.792-45.804-62.14 10.89" />
              <path fill="#a79fce" d="M905.403 509.182l17.296-32.137 43.347 34.913" />
              <path fill="#957fb3" d="M941.49 554.025l24.557-42.067-60.644-2.776" />
              <path fill="#8670a1" d="M886.825 536.195l18.578-27.013 36.088 44.843" />
              <path fill="#aa99c9" d="M913.517 588.085l27.974-34.06-54.665-17.83" />
              <path fill="#9480b3" d="M862.054 562.033l24.77-25.838 26.693 51.89" />
              <path fill="#8e71a8" d="M887.68 614.243l25.837-26.158-51.463-26.052" />
              <path fill="#a99bca" d="M839.1 581.678l22.954-19.645 25.625 52.21" />
              <path fill="#987fb4" d="M852.232 639.334l35.447-25.09-48.047-32.566" />
              <path fill="#866fa0" d="M811.66 600.256l27.973-18.578 12.6 57.656" />
            </g>
            <g id="left-wing-inner">
              <path fill="#7ba9ae" d="M348.815 277.6l-41.32-39.825 12.706 56.267" />
              <path fill="#768e98" d="M266.176 273.756l41.32-35.98L320.2 294.04" />
              <path fill="#dae6f3" d="M299.06 319.24l21.14-25.198-54.024-20.286" />
              <path fill="#80a6b0" d="M242.686 315.93l23.49-42.174 32.884 45.484" />
              <path fill="#7b8b9b" d="M289.024 347.854l10.036-28.614-56.374-3.31" />
              <path fill="#d9dff1" d="M234.145 364.083l8.54-48.153 46.34 31.924" />
              <path fill="#83a3b1" d="M288.81 378.497l.214-30.643-54.88 16.23" />
              <path fill="#828a9f" d="M242.473 410.848l-8.328-46.765 54.666 14.414" />
              <path fill="#d9d8ed" d="M297.352 404.442l-8.54-25.945-46.34 32.35" />
              <path fill="#899db2" d="M264.68 451.634l-22.207-40.786 54.88-6.406" />
              <path fill="#838aa0" d="M315.93 431.56l-18.578-27.118-32.67 47.192" />
              <path fill="#d8d3ea" d="M307.282 488.47l-42.6-36.836 51.248-20.073" />
              <path fill="#879fb2" d="M349.028 448.75l-33.098-17.19-8.648 56.91" />
            </g>
            <g id="left-wing-middle">
              <path fill="#8cb7c4" d="M199.23 178.625l25.946-18.47 13.773 52.422" />
              <path fill="#a7dde9" d="M218.45 230.3l20.5-17.723-39.72-33.952" />
              <path fill="#6a909a" d="M167.84 207.88l31.39-29.255 19.22 51.676" />
              <path fill="#88bdc4" d="M198.164 252.51l20.286-22.21-50.61-22.42" />
              <path fill="#a7cce4" d="M140.935 245.676l26.906-37.796 30.324 44.63" />
              <path fill="#718e9d" d="M178.732 285.18l19.432-32.67-57.23-6.834" />
              <path fill="#a8d5e7" d="M121.183 290.626l19.752-44.95 37.797 39.504" />
              <path fill="#90b1c5" d="M166.56 321.482l12.172-36.302-57.55 5.446" />
              <path fill="#778a9f" d="M112.32 337.818l54.24-16.336-45.377-30.856" />
              <path fill="#a7cce4" d="M162.076 365.15l4.484-43.668-54.24 16.336" />
              <path fill="#94a7c6" d="M113.602 393.23l48.474-28.08-49.755-27.332" />
              <path fill="#7e89a7" d="M168.482 406.47l-6.406-41.32-48.474 28.08" />
              <path fill="#aac1e3" d="M125.133 439.782l43.35-33.312-54.88-13.24" />
              <path fill="#95a5c5" d="M181.614 438.82l-13.132-32.35-43.35 33.312" />
              <path fill="#8586a8" d="M145.847 481.636l-20.714-41.854 56.48-.96" />
              <path fill="#abc0e3" d="M197.523 466.26l-15.91-27.44-35.766 42.816" />
              <path fill="#9a9fc7" d="M171.792 515.695l-25.945-34.06 51.676-15.374" />
              <path fill="#8585a9" d="M217.062 488.47l-19.54-22.21-25.73 49.435" />
              <path fill="#9a9fc7" d="M199.658 541.106l-27.866-25.41 45.27-27.227" />
              <path fill="#99a2c7" d="M242.473 508.755l-25.41-20.286-17.405 52.636" />
              <path fill="#aeb8de" d="M229.66 561.5l-30.002-20.394 42.815-32.35" />
              <path fill="#abbfe3" d="M270.34 524.237l-27.867-15.482L229.66 561.5" />
              <path fill="#6a909a" d="M238.95 212.577l25.838-14.84-39.612-37.583" />
            </g>
            <g id="left-wing-outer">
              <path fill="#6c949b" d="M164.425 145.74l27.76-17.19-41.854-40.252" />
              <path fill="#57787e" d="M109.972 119.688l40.36-31.39 14.093 57.442" />
              <path fill="#95c7d0" d="M133.568 172.325l30.857-26.585-54.453-26.052" />
              <path fill="#71939d" d="M77.194 154.068l32.778-34.38 23.596 52.637" />
              <path fill="#5e7681" d="M106.876 203.716l26.692-31.39-56.374-18.258" />
              <path fill="#99c1d0" d="M48.794 191.757l28.4-37.69 29.682 49.65" />
              <path fill="#7490a1" d="M88.298 232.864l18.578-29.148-58.082-11.96" />
              <path fill="#657687" d="M28.294 230.515l20.5-38.758 39.504 41.107" />
              <path fill="#9cbacf" d="M71.43 270.34l16.868-37.476-60.004-2.35" />
              <path fill="#7c8ca6" d="M11.638 277.173l16.656-46.658L71.43 270.34" />
              <path fill="#6d758f" d="M58.403 318.28l13.026-47.94-59.792 6.833" />
              <path fill="#a1b2d1" d="M2.135 327.78l9.503-50.607 46.765 41.106" />
              <path fill="#7b7397" d="M0 379.99l2.135-52.21 51.997 27.227" />
              <path fill="#8389aa" d="M58.403 318.28l-4.27 36.727L2.134 327.78" />
              <path fill="#a4accf" d="M56.48 400.064l-2.348-45.057L0 379.99" />
              <path fill="#8c86ae" d="M5.018 426.116L0 379.99l56.48 20.074" />
              <path fill="#7f739b" d="M64.49 440.21l-8.01-40.146-51.46 26.052" />
              <path fill="#a6a2cd" d="M15.16 466.154L5.02 426.116l59.47 14.093" />
              <path fill="#9085ae" d="M77.3 477.045L64.49 440.21l-49.33 25.944" />
              <path fill="#8572a1" d="M33.953 511.958L15.16 466.154l62.14 10.89" />
              <path fill="#a79fce" d="M94.597 509.182L77.3 477.045l-43.347 34.913" />
              <path fill="#957fb3" d="M58.51 554.025l-24.557-42.067 60.644-2.776" />
              <path fill="#8670a1" d="M113.175 536.195l-18.578-27.013-36.087 44.843" />
              <path fill="#aa99c9" d="M86.483 588.085l-27.973-34.06 54.665-17.83" />
              <path fill="#9480b3" d="M137.946 562.033l-24.77-25.838-26.693 51.89" />
              <path fill="#8e71a8" d="M112.428 614.243l-25.945-26.158 51.463-26.052" />
              <path fill="#a99bca" d="M160.9 581.678l-22.954-19.645-25.518 52.21" />
              <path fill="#987fb4" d="M147.77 639.334l-35.342-25.09 47.94-32.566" />
              <path fill="#866fa0" d="M188.34 600.256l-27.973-18.578-12.598 57.656" />
            </g>
          </defs>
        </svg>
      </div>
    </div>
  </div>

</template>


<script>

  export default {
    name: 'KolibriLoadingSnippet',
    $trs: {
      kolibriLoading: {
        message: 'Kolibri loading',
        context: 'Indicates Kolibri is loading',
      },
    },
  };

</script>


<style>
  .rel{
    position: relative;
    width: 100%;
    height: 100%;
  }

  .flapping-kolibri {
    /* Set the width of this to match the .svg width if you want to center it */
    width: 250px;
    margin: 0 auto;
    animation: fadeIn;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: both;
  }

  .svg {
    width: 250px;
    height: 250px;
  }

  .wing-inner {
    animation: flapInner 2s 1.2s both infinite;
  }

  .wing-middle {
    animation: flapMiddle 2s 1.2s both infinite;
  }

  .wing-outer {
    animation: flapOuter 2s 1.2s both infinite;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes flapInner {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes flapMiddle {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes flapOuter {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>
